@import "assets/styles/colors.scss";

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  cursor: pointer;
  color: #1898e3;
  text-decoration: underline;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-input {
  border: none;
  padding: 10px;
  border-radius: 3px;

  &::placeholder {
    color: #9d9e9f;
    font-size: 14px;
  }
}

.truncate {
  width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.clear_btn {
  position: absolute;
  right: 30px;
  top: 26px;
  z-index: 10;
  cursor: pointer;
}

.nav-link.inactive {
  color: #9d9e9f;
}

.nav-item {
  font-weight: 500;
  .menu.active {
    color: #495968;
    border-bottom: 4px solid #1898e3;
  }
}

.polygon-map {
  height: 100%;
  width: 100%;
}

input.app_slider[type="checkbox"] {
  visibility: hidden;
  &:checked + label {
    background-color: $success;
    &:before {
      transform: translateX(31px);
      background-color: #fff;
    }
  }
}

input.app_slider + label {
  display: flex;
  width: 60px;
  height: 30px;
  border: transparent 6px solid;
  border-radius: 99em;
  position: relative;
  transition: transform 0.25s ease-in-out;
  transform-origin: 50% 50%;
  cursor: pointer;
  background-color: $danger;
  &:before {
    transition: transform 0.25s ease;
    content: "";
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: $white;
    border-radius: 50%;
    top: -1px;
    left: -1px;
  }
}

input::placeholder {
  color: #95a7b7 !important;
}

.checkbox-column {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  .custom-control-label {
    padding-inline-start: 10px;
    font-size: 14px;
    color: #6c757d !important;
  }
}
thead {
  border-top-style: hidden !important;
}

.checkbox-label-group {
  .custom-control {
    display: inline-flex;
  }

  .custom-control-label {
    font-size: 14px;
    padding-inline-start: 10px !important;
    color: #6c757d !important;
  }
}

.col-row-reverse {
  justify-content: flex-end;
  display: flex;
}

.rdrMonth .rdrMonthName {
  margin-top: 25px !important;
}

[data-reach-combobox-popover] {
  z-index: 99999999 !important;
}

.pac-container {
  z-index: 99999999 !important;
}

.z-index-0 {
  z-index: 0 !important;
}

.form-check {
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 0 !important;
  min-width: 20px !important;
  min-height: 20px !important;
  margin-bottom: 0 !important;
  margin-inline-start: 0 !important;
  margin-inline-end: 5px;
}

.form-check .form-check-input {
  margin: 0 !important;
}

.required-field {
  &:after {
    content: " *";
    color: red;
  }
}

.error-msg {
  width: 100%;
  height: 52px;
  background-color: #ff5f5f;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  padding: 20px;
  z-index: 100;
  max-width: 1000px !important;
  #close {
    cursor: pointer;
    filter: invert(100%);
  }
}

.modal-dialog {
  &:lang(ar) {
    direction: rtl;
  }
  &:lang(en) {
    direction: ltr;
  }
}
.required-star {
  padding: 5px;
  color: red;
}

.checkbox-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  .checkbox-input {
    margin-inline-end: 10px;
  }

  .checkbox-label {
    font-size: 14px;
    color: #6c757d;
  }
}

.table-row-hand {
  cursor: move;
  pointer-events: none;
}
